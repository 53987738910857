// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@use "@ng-select/ng-select/themes/default.theme.css";
@use "shared-styles/mapbox-gl-styles";
@use "variables";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$transversal-database-primary: mat.define-palette(mat.$blue-palette);
$transversal-database-accent: mat.define-palette(
  mat.$amber-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$transversal-database-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$transversal-database-theme: mat.define-light-theme(
  (
    color: (
      primary: $transversal-database-primary,
      accent: $transversal-database-accent,
      warn: $transversal-database-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($transversal-database-theme);

.bg-engie-gradient {
  background: linear-gradient(135deg, #00aaff 0.89%, #23d2b5 94.05%);
}

.tdb-card {
  padding: 20px;
  background-color: white;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #fafafa !important;
}

.searchbar {
  margin-bottom: 1rem;

  & + .nj-alert {
    margin-top: 0px;
  }
}

.mat-row:nth-child(even) {
  background-color: #fafafa;
}

.mat-tooltip.tooltip {
  font-size: 13px;
}

.mat-form-field.mat-form-field-appearance-outline {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
    border-radius: 5px !important;
  }

  .mat-form-field-infix {
    padding: 12px 0.5em 12px !important;
    margin-top: -5px !important;
  }
  & + button {
    margin: 0.25em 0;
  }
}

.mat-form-field.mat-form-field-appearance-outline.mat-form-field-should-float {
  .mat-form-field-label-wrapper {
    margin-top: 5px !important;
  }
}

.clickable {
  cursor: pointer;
}

.tdb-disabled {
  color: #aaa !important;
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
  mat-icon {
    color: #aaa !important;
  }
}

.fg-engie-gradient {
  background: variables.$engie-gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

mat-progress-bar {
  margin-bottom: -4px !important;
}

.no-margin-bottom,
.no-margin-bottom > * {
  margin-bottom: unset !important;
}

.tdb--disabled {
  opacity: 0.3;
  pointer-events: none;
  transition: ease-in-out 0.3s;
}

.eased {
  transition: ease-in-out 0.3s;
}

.tdb-snack-ok {
  background-color: variables.$engie-secondary-green;
  color: black;
}

.tdb-snack-nok {
  background: variables.$engie-warn;
  color: #fff;
}

.tdb-error-message {
  color: variables.$engie-warn;
  margin: 8px;
  font-size: 20px;
  font-weight: 300;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  border-top-color: variables.$engie-blue;
  animation: spinner 0.8s linear infinite;
}

.spinner {
  span {
    opacity: 0 !important;
  }
}

.nowrap {
  white-space: nowrap;
}

.mat-cell {
  border: 1px solid #f0f0f0;
  white-space: nowrap;
}

.mat-header-cell {
  background-color: #fafafa;
  // white-space: nowrap;
}

.mat-sort-header-container .mat-sort-header-content {
  text-align: left;
}

.ellipsis {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.tip {
  background: rgba($color: #000000, $alpha: 0.7);
  padding: 8px;
  border-radius: 4px;
}

.fit {
  width: 100%;
  height: 100%;
}

.unclickable {
  pointer-events: none;
}

.mat-fab {
  mat-icon {
    color: white;
  }
}

.mat-table-sticky-left {
  border-right: 1px solid #fafafa;
}
th.mat-table-sticky-border-elem-left {
  border-right: 0.8px solid #f0f0f0;
}
td.mat-table-sticky-border-elem-left {
  border-right: 0.8px solid rgba(0, 0, 0, 0.12);
}

.mtx-grid-toolbar-content {
  width: 100%;
  width: -moz-available; /* For Mozzila */
  width: -webkit-fill-available; /* For Chrome */
  width: stretch; /* Unprefixed */
}

.mtx-grid {
  max-height: 75vh;
}

.mtx-grid-statusbar {
  min-height: unset !important;
  padding: unset !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.mat-menu-panel {
  max-width: unset !important;
}

.mapboxgl-ctrl-top-left {
  display: flex;
}

.mapboxgl-ctrl button:not(:disabled):hover {
  background-color: #ffffff;
}

.gis-map.pin-toggled .mapboxgl-canvas-container {
  cursor: crosshair;
}

.break-spaces {
  white-space: break-spaces;
}

.published-warning {
  color: variables.$engie-blue;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

tdb-smart-select
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  padding-bottom: 2px;
  top: unset;
}

mat-option[disabled="true"] {
  display: none;
}

@media (min-width: 1200px) {
  .mtx-grid-footer,
  .tdb-table-footer {
    align-items: center;
    display: flex !important;

    .mtx-grid-statusbar,
    .tdb-table-statusbar {
      flex: 1 1 0%;
    }

    .mtx-grid-pagination,
    .tdb-table-pagination {
      flex-grow: 1;
    }
  }
}
